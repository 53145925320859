<template>
  <div v-show="isActive" class="link-api-content">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TabView",
  props: {
    title: {
      type: String,
      default: "Tab",
    },
    steps: {
      type: Number,
    },
    special: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: true,
    };
  },
};
</script>
