<template>
  <div class="link-api">
    <ul
      @mousedown="enterMouse"
      @mouseup="leaveMouse"
      @mousemove="moveMouse"
      class="link-api-menu"
      ref="linkApiMenu"
    >
      <li
        v-for="(tab, index) in tabs"
        :key="tab.title"
        @click="selectTab(index)"
        class="item"
        :class="tab.isActive ? 'active-link-api' : 'item-api'"
        :data-link-api-tour-step="tab.steps"
      >
        <span :class="tab.special ? 'special' : ''">
          {{ tab.title }}
        </span>
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TabsView",
  props: {
    selected: {
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      tabs: [], // all of the tabs
      movedMouse: false,
      startMove: undefined,
      move: undefined,
    };
  },
  mounted() {
    this.selectTab(this.selected);
  },
  methods: {
    selectTab(i) {
      // loop over all the tabs
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === i;
      });
    },
    enterMouse(e) {
      let innerSlider = document.querySelector(".item");
      console.log("Enter");
      this.movedMouse = true;
      this.startMove = e.offsetX - innerSlider.offsetLeft;
    },
    leaveMouse() {
      console.log("Leave");
      this.movedMouse = false;
    },
    moveMouse(e) {
      if (this.movedMouse) {
        let innerSlider = document.querySelector(".item");
        this.move = e.offsetX;
        innerSlider.style.left = `${this.x - this.startX}px`;
        console.log(e.offsetX);
      }
    },
  },
  created() {
    this.tabs = this.$children;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_setting.scss";
</style>
