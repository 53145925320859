export default {
  data() {
    return {
      tourOptions: {
        enabledButtons: {
          buttonPrevious: false,
          buttonSkip: false,
        },
        labels: {
          buttonNext: "التالي",
          buttonStop: "ابدأ الأن",
        },
        highlight: true,
        enableScrolling: false,
      },
      startTour(name) {
        this.$tours[name].start();
      },
      step: -1,
    };
  },
  methods: {
    runWalkthrough() {
      let walkthrough = JSON.parse(localStorage.getItem("walkthrough"));
      if (this.$store.state.walkthrough_value) {
        return true;
      } else {
        if (walkthrough == 0) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
};
